import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

interface MenuItem {
  path: string;
  label: string;
  children?: MenuItem[];
}

interface Props extends RouteComponentProps {}

const AccountActions: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const { data, error, loading } = useMenu(
    'menu-notifications',
    i18n.language,
    true
  );

  const allowedPaths = [
    '/secure/application/281',
    'https://my.rotary.org/district/conferences/feedback/PR',
    'https://my.rotary.org/user/delegation',
  ];

  const descriptionMapper: Record<string, string> = {
    '/secure/application/281': t(
      'account-actions-page.invitations.description',
      `View your invitation(s) to serve on an RI or TRF committee or as the president's representative at a district conference.`
    ),
    'https://my.rotary.org/district/conferences/feedback/PR': t(
      'account-actions-page.president.description',
      `View your invitation(s) to serve on an RI or TRF committee or as the president's representative at a district conference.`
    ),
    'https://my.rotary.org/user/delegation': t(
      'account-actions-page.delegation.description',
      'You can delegate temporary permission to conduct online Rotary business on your behalf to any individual in your club or district who h...'
    ),
  };

  function extractMatchingPaths(
    data: MenuItem[],
    allowedPaths: string[]
  ): MenuItem[] {
    const result: MenuItem[] = [];

    const isAllowedPath = (path: string) => allowedPaths.includes(path);

    const checkChildren = (node: MenuItem) => {
      if (isAllowedPath(node.path)) {
        result.push(node);
      }
      if (node.children && Array.isArray(node.children)) {
        node.children.forEach((child: MenuItem) => checkChildren(child));
      }
    };

    data.forEach((item: MenuItem) => checkChildren(item));

    return result;
  }

  function sortArrayByDescriptionMapping(
    array: MenuItem[],
    descriptionMapper: Record<string, string>
  ): MenuItem[] {
    return array.sort((a: MenuItem, b: MenuItem) => {
      const aPath = a.path;
      const bPath = b.path;

      const keys = Object.keys(descriptionMapper);

      const aIndex = keys.indexOf(aPath);
      const bIndex = keys.indexOf(bPath);

      return aIndex - bIndex;
    });
  }

  const [finalData, setFinalData] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (data?.length) {
      const matchedObjects = extractMatchingPaths(data, allowedPaths);
      setFinalData(
        sortArrayByDescriptionMapping(matchedObjects, descriptionMapper)
      );
    }
  }, [data]);

  return (
    <OneColumn>
      <h1 className="font-sans font-semibold text-[58px] leading-[70px] tracking-normal mt-5">
        {t('account-actions-page.title', 'My Account Actions')}
      </h1>
      {loading && <Loading />}
      {error && <p>{error?.message}</p>}
      {data && !!finalData?.length && !loading && !error && (
        <div className="grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 gap-6 mt-11 mb-11">
          {finalData.map((datum: MenuItem) => {
            return (
              <a
                key={datum?.label}
                href={datum?.path}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#323C44] cursor-pointer rounded-[1em] shadow-md hover:shadow-md active:shadow-sm transition-all p-4 block no-underline focus:outline-none"
              >
                <h5 className="font-sans font-semibold text-[#323C44] text-[18px] leading-[27px] tracking-normal">
                  {datum?.label}
                </h5>
                <p className="font-sans font-normal text-[#323C44] text-[16px] leading-[24px] tracking-normal">
                  {descriptionMapper[datum.path]}
                </p>
              </a>
            );
          })}
        </div>
      )}
    </OneColumn>
  );
};

export default AccountActions;
